import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w07GL05Ueben = () => (
  <Layout>
    <SEO title="w07GL05Ueben" />

    <h1 id="ueberschrift">Gemeinsam Lesen</h1>
    <h2>Gemeinsam Üben &emsp;  ©&nbsp;1989</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w07GL05Ueben&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Bei dem Versuch einen Text zu lesen scheinen
        die Buchstaben über die Seiten zu tanzen und sich nur schwer entziffern zu lassen...<br />
        Dies kann mehrere Ursachen haben.
      </p>
      <p>
        Meist ist es einfacher etwas zu verstehen, das erlebt oder zumindest angeschaut werden kann.
        Aus diesem Grund bietet dieser Kurs mehr als einen einfachen Frontal-Vortrag.
        Durch die Mischung meiner Kenntnisse mit Ihren Situationen und Interessen
        können wir den Kursinhalt weitmöglichst Ihren Bedürfnissen anpassen.
        So können Sie Methoden entwickeln, welche Ihnen helfen können, sich besser auf einen Text zu konzentrieren.
      </p>
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        <b>Auch Lese- &amp; Schreib-Anfänger, ob groß oder klein, sind herzlich willkommen!</b>
      </p>

      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Vortrag (+Mitmachkurs)
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Tisch pro Teilnehmer + 1 Tisch extra<br/>
        Materialbedarf: + Stift und Schmierpapier +
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 1 Tisch
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w07GL05Ueben
